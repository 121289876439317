export default function SvgIconChildren() {
  return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.493 1.66699H6.50964C3.4763 1.66699 1.66797 3.47533 1.66797 6.50866V13.4837C1.66797 16.5253 3.4763 18.3337 6.50964 18.3337H13.4846C16.518 18.3337 18.3263 16.5253 18.3263 13.492V6.50866C18.3346 3.47533 16.5263 1.66699 13.493 1.66699ZM10.0013 13.2337C8.21797 13.2337 6.76797 11.7837 6.76797 10.0003C6.76797 8.21699 8.21797 6.76699 10.0013 6.76699C11.7846 6.76699 13.2346 8.21699 13.2346 10.0003C13.2346 11.7837 11.7846 13.2337 10.0013 13.2337ZM14.9346 5.73366C14.893 5.83366 14.8346 5.92533 14.7596 6.00866C14.6763 6.08366 14.5846 6.14199 14.4846 6.18366C14.3846 6.22533 14.2763 6.25033 14.168 6.25033C13.943 6.25033 13.7346 6.16699 13.5763 6.00866C13.5013 5.92533 13.443 5.83366 13.4013 5.73366C13.3596 5.63366 13.3346 5.52533 13.3346 5.41699C13.3346 5.30866 13.3596 5.20033 13.4013 5.10033C13.443 4.99199 13.5013 4.90866 13.5763 4.82533C13.768 4.63366 14.0596 4.54199 14.3263 4.60033C14.3846 4.60866 14.4346 4.62533 14.4846 4.65033C14.5346 4.66699 14.5846 4.69199 14.6346 4.72533C14.6763 4.75033 14.718 4.79199 14.7596 4.82533C14.8346 4.90866 14.893 4.99199 14.9346 5.10033C14.9763 5.20033 15.0013 5.30866 15.0013 5.41699C15.0013 5.52533 14.9763 5.63366 14.9346 5.73366Z"
          fill="black"
        />
      </svg>
  );
}
