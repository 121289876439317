export const resources = {
  en: {
    translation: {
      "15Min":"15Min",
      "1Hr":"1Hr",
      "Game":"Game",
      "for.you":"For you",
      "ava.today": "Available TODAY",
      "games": "Games",
      "gamers": "Gamers",
      "referral.withdraw.hint": "You will need to pay a withdraw fee of $SGD0.29 and TransferWise fees.",
      "credit.referral": "Earn a 10% commission on your friends' Credits withdrawals during their first 3 months of signing up.",
      "order.warning":
        "Immediate refunds in credits are available within 72 hours of purchase. After 72 hours, credits may be converted to cash. Please book 1-2 days ahead.",
      "Double Dates": "Double Dates",
      "new.users": "New users",
      "share.profile": "Share profile",
      "reason.required": "Please write a reason",
      "no.profile": "No profiles for this selection.",
      "another.service": "Try searching another service or filter a different option again.",
      undo: "Undo",
      accept: "Accept",
      reject: "Reject",
      "rejected.reason": "Rejected reason",
      "cancel.reason": "Cancel reason",
      "social.descrip":
        "Check me out at this Rent a Friend, Rent a Date platform.",
      send: "Send",
      "final.price": "Final Price",
      "cab.fare": "Cab fare",
      date: "Date",
      time: "Time",
      "wait.user.respond": "Waiting for user to response",
      confirm: "Confirm",
      "make.payment": "Make payment",
      "pay.to.chat": "Pay to unlock chat",
      block: "Block",
      unblock: "Unblock",
      delete: "Delete",
      "not.accepting.orders":
        "Sorry, I am currently not accepting any new orders",
      "no.services": "This user does not provide any services",
      "under.review": "This profile is still under review",
      "confirm.again":
        "Expired! Please send another request order to confirm user's availability again.",
      "order.not.confirm":
        "This order is NOT confirm! The order is only confirmed after your client make the payment.",
      "phone.existed": "Phone number already existed with another account.",
      "wrong.code": "Wrong verification code",
      "We do NOT allow any forms of physical contact or any form of NSFW services. Rule violation will result in account suspension.":
        "We do NOT allow any forms of physical contact or any form of NSFW services. Rule violation will result in account suspension.",
      "Exchanging of contacts before making payment on the platform will result in account suspension.":
        "Exchanging of contacts before making payment on the platform will result in account suspension.",
      "Please avoid off-platform transaction to protect your personal information and getting scam. Any forms of attempts will result in account suspension.":
        "Please avoid off-platform transaction to protect your personal information and getting scam. Any forms of attempts will result in account suspension.",
      "expires.in": "Expires in",
      "off-platform.warning":
        "Our on-platform transaction by using Credits protects your <b>personal information</b> and prevent you from getting <b>scam</b>. We <b>BAN</b> users who exchange contacts before payment is made.",
      read: "Read",
      "order.expired":
        "Expired! Your client has not make any payment yet. Do not continue.",
      expired: "Expired",
      "bind.phone": "Bind Phone",
      "skip.step": "Skip this step",
      "subscribe.button": "Subscribe",
      "bind.title": "Subscribe PREMIUM or Recharge Credits",
      "bind.description":
        "We want to know that you really want to use our services. So please subscribe to PREMIUM or top up your wallet to at least {{credit}} credits. Otherwise, bind your phone number for free.",
      filter: "Filter",
      "recently.active": "Recently Active",
      "highest.ratings": "Highest Ratings",
      "lowest.price": "Lowest Price",
      "withdraw.warning": "Please enter a withdraw amount",
      "withdraw.hint":
        "According to Terms of Service, Bancar.me platform will charge <b>25%</b> of the withdraw amount as commission fee.<br/><br/>You will also pay a withdraw fee for <b>S$0.29/time</b>. You can withdraw up to <b>500 Credit</b> at one time. <b>Withdrawals will normally arrive within/after 24 hours.</b>",
      recharge: "Recharge",
      withdraw: "Withdraw",
      "profile.inactive.hint":
        "Your profile is on INACTIVE mode. Please go to 'SECURITY' > 'Hide profile'",
      cancel: "Cancel",
      "unlock.reason1":
        "By default, all chats are locked to prevent unnecessary chatting. Unlocking a chat might prevent you from getting paid for certain services such as E-Meets.",
      "unlock.reason2": "You can always lock or unlock a chat when necessary.",
      "unlock.chat": "Unlock chat",
      "lock.chat": "Lock chat",
      lock: "Lock",
      unlock: "Unlock",
      "SERVICE.ORDERS": "Service Orders",
      "request.refund": "Request refund",
      "view.order": "View order",
      "give.review": "Give review",
      verification: "Verification",
      "switch.to.inactive": "Hide profile",
      privacy: "Privacy",
      rules: "Rules",
      "voice.recording": "Voice recoder",
      "block.user": "Blocked users",
      "re.connect.to": "Reconnect to",
      "connect.to": "Connect to",
      "view.all.reviews": "View all Reviews",
      reviews: "Reviews",
      "turn.on.noti": "Turn on notification",
      "turn.on": "TURN ON",
      "how.to.upload.v.doc":
        "Take a clear selfie of you holding your government issued ID (driving license, passport, etc...). You may censor any sensitive information on the document except for date of birth and face photo.",
      "verified.badge": "Verified badges helps to prove your identity",
      "verified.done":
        "Verified government-issued ID and selfie for age confirmation.",
      "label.done": "Done",
      "choose.services": "Choose a Service",
      "gaming.guidelines": "Upload Gaming Profile Guidelines",
      photos: "Photos",
      "join.tele": "JOIN THIS !!",
      qna: "Ask a Question",
      "profile.info": "Profile information",
      "best.label": "Best Ebuddies",
      "new.comer": "New users",
      favourites: "Favourites",

      "question15.faq": "What is Balance Credit?",
      "question16.faq": "What is Pending Credit?",
      "question17.faq": "What is Credit Income?",

      "question13.faq": "What is Credit Penalty?",
      "answer13.faq":
        "'Penalty Credits' refers to the amount of credits that will be deducted from your 'Income Credit'. For example, if you have 10 Income Credit and 1 Penalty Credit. You will only be able to withdraw 9 Credits to Cash. We give penalty for users who misconduct.",

      "question14.faq":
        "Can I get paid immediately after I completed my service?",
      "answer14.faq":
        "Yes! Your client is able to transfer the Pending Credit to your Income Credit immediately after they have have confirm that the service is completed.",

      "current.location": "I am currently at this location",
      "food.example": "Examples: Halal, Seafood allergic",
      "food.pref.label": "Food Preferences (Optional)",
      "add.info.label": "Additional information",
      "ethnicity.label": "Ethnicity",
      "username.label": "Username",
      "dob.label": "Date of Birth",
      "gender.label": "Gender",
      "bio.label": "Bio",
      "availability.example": "Example: Wednesday only",
      "public.profile.desc": "Everybody can see your profile",
      "private.profile.desc":
        "Only users that paid for monthly subscriptions  or spend XXX amount of credits will be able to see your profile",
      "upload.voice": "Upload Voice recording",
      videos: "Videos",
      "upload.media": "Upload media",
      "label.set.price": "Set price limit",
      "label.add.services": "Add services",
      "label.update.services": "Update services",
      "label.services": "Services",
      "dd.required": "*Please invite a user to join your double date service",
      "intro1.header": "High income",
      "intro2.header": "Flexible timing",
      "intro3.header": "Make new friends",

      "intro.review": "Bancar.me application under review",
      "intro.under.review":
        "It usually takes within 24-48 hours. You may still update your application details.",
      "intro.title": "What is a Bancar.me member?",
      "intro.description":
        "A Bancar.me member provide companionship, gaming or sports services.",
      "intro.rejected": "Bancar.me application has been rejected",
      "intro.why": "Why become a Bancar.me?",

      "intro.why1.title": "High income",
      "intro.why1.desc":
        "Earn more than 5000 Credits per month for making friends and gaming.",

      "intro.why2.title": "Flexible timing",
      "intro.why2.desc": "You are your own boss. Anytime, Anywhere.",

      "intro.why3.title": "Expand network",
      "intro.why3.desc":
        "Get to know successful individuals or gamers from all over the world",

      "intro.eligible.title": "Who is eligible?",
      "intro.eligible1": " 18 years old or above",
      "intro.eligible2":
        " Able to provide services in at least 1 category in Bancar.me",
      "intro.eligible3":
        " Enthusiastic , good at communication, non-toxic and do not conduct NSFW (Not safe for work) services",

      "intro.about.title": "About Bancar.me",
      "intro.about.desc":
        "Bancar.me is a rent a friend, rent a date, social platform to connect everyone in the world through",

      "apply.button": "Apply for Free",
      "next.button": "Next",
      "update.button": "Update",
      "update.app.button": "Update application",
      "submit.app.button": "Submit application",
      "whereyou.label": "I am currently at this location",
      "require.location.label": "Require",
      "location.hint": "Please click on the given names",

      "choose.service.label": "Choose a service",
      "update.service.button": "Update service",
      "add.service.button": "Add service",

      "upload.media.label": "Upload media",
      "attire.rules": "Read attire rules",
      "voice.rules": "Read voice rules",

      "intro.question": "Why become a Bancar.me?",
      "income.title": "High income",
      "income.description":
        "Earn more than 5000 Credits per month for making friends and gaming.",

      "timing.title": "Flexible timing",
      "timing.description": "You are your own boss. Anytime, Anywhere.",

      "network.title": "Expand network",
      "network.description":
        "Get to know successful individuals or gamers from all over the world.",

      "history.tab": "Last viewed",
      "order.tab": "Order",
      "wallet.tab": "Wallet",
      "profile.tab": "Profile",
      "media.tab": "Media",
      "security.tab": "Security",
      "location.tab": "Location",
      "notification.tab": "Notification",
      "chats.tab": "Chats",
      "privacy.tab": "Privacy",

      "submit.button": "Submit",
      "save.button": "Save",
      "delete.account.button": "Delete Account",

      "request.button": "Request an Order",
      "refund.disclaimer":
        "We only issue refund within 72 hours from the date of purchase",

      "orders.label": "Orders",
      "views.tab": "Views",

      "loyalpoints.description":
        "Our platform give users cashback and other privileges according to the total number of Credits they bought.",
      "shareprofile.label": "Share profile",
      "rules3.warning":
        "We ban user who conduct NSFW services or gives out third party messenger (off-platform transaction)",
      "rules2.warning":
        "We ban users who give their contacts in their rejected/cancel reasons.",
      "rules.warning":
        "We ban user who ask for NSFW services or gives out third party messenger on addition information",
      "askpayment.instruction":
        "Copy and send this link to your client so that you can get paid immediately.",
      "error.label": "Unexpected error",
      "sendcredit.disclaimer": "I understand that a refund cannot be issued.",
      "sendcredit.description":
        "The credits that you sent to the user will be transfer to the their bank account immediately.",
      "confirmation.label": "Confirmation",
      "getpaid.label": "Get paid immediately",
      "servicecompleted.label": "Is this service completed?",

      "emeet.tab": "EMeet",
      "meetup.tab": "Meetup",
      "games.tab": "Games",
      "sports.tab": "Sports",

      "babe.button": "Be a Babe",
      "login.button": "Login",
      "signup.button": "Sign up",
      "signout.button": "Sign out",
      "cancel.button": "Cancel",
      "share.button": "Share",
      "reviews.button": "Reviews",
      "report.button": "Report",
      "rent.button": "I want to rent someone",
      "join.button": "Join as a babe for FREE",
      "rejected.button": "Check rejected reason",
      "broadcast.button": "BROADCAST",
      "upgrade.button": "Upgrade to Premium",
      "updatesub.button": "Update Subscription",
      "iamfreetoday.button": "I am free today",
      "notfreeanymore.button": "I am not free anymore",

      "msg.button": "Chat",
      "straight.description":
        "Individual who are sexually and/or romantically attracted to the opposite sex.",
      "gay.description":
        "Individuals who are sexually and/or romantically attacted to the same sex.",
      "lesbian.description": "Women who are sexually attracted to women.",
      "bisexual.description":
        "Individuals who are sexually attracted to both men & women. Can also reflect people attracted to multiple genders/gender identities.",
      "asexual.description":
        "Individuals who don't experience sexual attraction.",
      "demisexual.description":
        "Individuals who don't experience sexual attraction unless they form a strong emotional connection with someone.",
      "hemoflexible.description":
        "Individuals who identify as predominantly homosexual but are open to a heterosexual encounter.",
      "heteroflexible.description":
        "Individuals who identify as predominantly heterosexual but are open to a heterosexual encounter.",
      "pansexual.description":
        "Individuals who are attracted to people from the full spectrum of sex, gender, and gender identity.",
      "queer.description":
        "A term sometimes used to refer to the entire LGBTQ+ community & the spectrum of sexual orientations & gender identities. For others, queer may reflect their sexual identity, and the label they use to identify themselves.",
      "questioning.description":
        "Individuals who are questioning & exploring their sexual orientations, gender identity, or both.",
      "grayAsexual.description":
        "Individuals on the asexual spectrum who experience sexual & romantic attraction, but only rarely.",
      "reciprosexual.description":
        "Individuals who don't experience sexual attraction to someone until they know that the person is attracted to them.",
      "akiosexual.description":
        "Individuals who experience sexual attraction but do not want it be reciprocated, and would be uncomfortable if it were.",
      "aceflux.description":
        "Individuals whose sexual orientation fluctuates along the spectrum between asexual and sexual.",
      "grayromantic.description":
        "Individuals on the aromantic spectrum who experience romantic attraction but only rarely or weakly.",
      "demiromantic.description":
        "Individuals who only experience romantic attraction after developing an emotional connection beforehand.",
      "recipromantic.description":
        "Individuals who don't experience romantic attraction to someone until they know the person is attracted to them.",
      "akioromantic.description":
        "Individuals who may experience romantic attraction but don't want it reciprocated.",
      "aroflux.description":
        "Individuals whose romantic orientation fluctuates along the spectrum between aromantic and romantic.",

      "coin.hint":
        "Bancar.me uses virtual currency known as 'credit'. 1.00 credit = $1.00 SGD",

      "signout.dialog": "Do you want to sign out?",
      "searchlocation.hints": "Please click on the given country names",
      "howtorent.hints":
        "Your profile needs to be upgraded to premium (pay monthly subscription fees) in order to rent. Only premium subscriber users are able to rent a date.",
      "babe.hints":
        "It's FREE to join as a babe. Once your profile is listed on the website, there is nothing much you need to do. If someone wants to rent you, they will text you.",
      "rejected.hints": "You might need to resubmit your application again.",
      "maintenance.label": "Singapore user might experience problem",
      "changelocation.label": "Change location",
      "filterby.label": "Filter by",
      "setvalue.label": "Please set a value",
      "optional.label": "Optional",
      "remarks.label": "Remarks",
      "comingfrom.label": "Which area are you coming from?",
      "from.label": "From",
      "to.label": "To",
      "setfreetoday.label":
        "I am free today from {{convertFromText}} - {{convertToText}}, {{comingText}}. {{infoText}}",
      "race.label": "Race",
      "availability.label": "Availability",
      "height.label": "Height",
      "drinks.label": "Drinks",
      "locationat.label": "Location at",
      "activities.label": "Activities",
      "numberofmeetups.label": "Number of meetups",
      "foodpreferences.label": "Food Preferences",
      "iamfreetoday.label": "Scroll RIGHT to see who is available TODAY",
      "whoisfreetoday.label":
        "{{number_of_profiles}} profile{{plurals}} {{is_or_are}} available TODAY",

      "rent.menulist": "Rent",
      "messages.menulist": "Messages",
      "FAQ.menulist": "FAQ",
      "terms.menulist": "Terms of service",
      "location.menulist": "Locations",
      "contactus.menulist": "Contact us",

      "rent.toolbar": "RENT",
      "FAQ.toolbar": "FAQ",
      "terms.toolbar": "TERMS",
      "location.toolbar": "LOCATIONS",
      "contactus.toolbar": "CONTACT",

      "unlimited.benefit": "No transaction fees",
      "access.benefit": "Acccess to private profiles",
      "discount.benefit": "10% more Credit and 0% transaction fees",

      "subscription.disclaimer":
        "I understand that we do not offer refunds and this subscription will be automatically renewed accordingly.",
      "platform.disclaimer":
        "We do not condone adult contents, escorts, prostitution or any form of fraudulent activities on our platform. All meetups MUST be at a public dine-in place with crowd. Anyone found out violating our T&C will be banned immediately.",

      "question1.faq": "What is Bancar.me about?",
      "question2.faq": "What can we use it for?",
      "question3.faq": "How to join as a Babe?",
      "question4.faq": "How do i rent?",
      "question5.faq": "How do i get paid when i join as a babe?",
      "question6.faq": "What is the important rules and guidelines?",
      "question7.faq": "How do we review and verify Bancar.me profiles?",
      "question8.faq": "When should i refund?",
      "question9.faq": "What is the commission on Credit withdraws?",
      "question10.faq": "Do you support off-platform transaction?",

      "question11.faq": "Why is the chat locked?",
      "question12.faq": "Cancellation and Refund Policy",

      "answer1.faq":
        "Bancar.me a platform that allow users to rent a date, rent a friend, rent a gamer or any other social purposes.",
      "answer2.faq":
        "You can rent a member to hang out with, go to a cafe or restaurant with, or someone to go with you to a party or event. Rent someone to teach you a new skill or hobby, or to show you around an unfamiliar town. Our members also offer E-Meets, relationship advice and other online services. You can also hire a professional gamer here!",
      "answer3.faq":
        "It is FREE to join as a Babe. Click on black button 'Be a Babe' and submit your application. Application review takes within 48 hours. Please read our guideline to speed up application process.",
      "answer4.faq":
        "Send an order request, and once accepted, make the payment to unlock the chat. For the best experience, book 1-2 days in advance to ensure a suitable interaction time for both parties. Be cautious not to book too far ahead, as payments are released to users within 3-7 days. To safeguard your credits, request refunds within 72 hours of purchase.",
      "answer5.faq":
        "PayNow and WISE for now. Other payment methods works too, but it might take a long time.",
      "answer6.faq":
        "This is strictly platonic (No touching at all) platform. We do not allow adult contents, prostitution or any form of fraudulent activities on our platform. All meetups MUST be at a public dine-in place with crowd. Please respect our members. If you violate the rules, you will be banned from using our platform. Customer(s) with/without Premium subscription who violate our rules will also be banned from using our platform.  We will NOT refund your credits",
      "answer7.faq":
        "We use Instagram verification, Government issued ID for profile verification and also allow users to give reviews/feedbacks for every meetup they went. We do not collect, use, or disclose an individual's document. Please censor all sensitive information except for date of birth and face photo.",
      "answer8.faq":
        "Common refund reasons include harassment, non-consensual recording, negative attitude, and suspicious transactions. If you ever feel uncomfortable during an order, you should leave and initiate a refund and report the incident to customer service by emailing hello@Bancar.me.com. Note our refund window is 3 days after completion. Completed orders won’t be able to be refunded if they are more than three days old.",
      "answer9.faq":
        "It is a 25% platform fee and 0.29 SGD fee for Credit withdrawal. The commission on withdraw keeps the platform operates and allow us to market for more clients. Thank you for your support!",
      "answer10.faq":
        "We DO NOT support off-platform transaction. Any user who exchanges contacts before a payment is made, will result in account suspension. Our on-platform transaction by using Credits protects your personal information and prevent you from getting scam. We ban users who are trying to do off-platform transaction. When a client pay through our platform, they have agreed on the terms of servcies stated on our website.",

      "answer11.faq":
        "All chats on our platform are locked by default to prevent unnecessary conversations. Chats can only be unlocked and used for messaging when the recipient decides to do so. This policy ensures focused and relevant communication for all users.",
      "answer12.faq":
        "A 100% Credit refund is given if you cancelled the meetup within 72 hours from the original date of payment. A 100% Credit refund is applicable if your date did not turn up or when your date cancelled the meetup. If the cancellation is made less than 6 hours to the scheduled meet-up time, only 50% Credit of the payment made will be refunded. In an event, where the Member is late, for instance, 15 mins, he/she will stay 15 mins more to compensate for the time gone. You will also have a choice of getting a full refund if he/she is late by more than 30 mins if You do not wish to continue with the date. In the event if Your date did not turn up, You will get a 100% Credit refund and your date will receive a penalty from us.",

      "credit.income":
        "“Credit income” refers to the income you have generated through providing services and collecting tips. Income can be withdrawn.",
      "credit.balance":
        "“Credit” is a virtual currency used on Bancar.me, it can be used to pay for services and tips. 1.00 Credit = 1.00 SGD\n\n“Credit balance” is the remaining deposit on your account that could be spent. The money in “Credit balance” is a non-withdrawable currency that can only be spent on the platform.",
      "credit.pending":
        "Pending Credit refers to your Credit income that is within the Security Period (3-7 days). They will be transferred to your account after this period.\n\nIf you’ve completed less than 3 orders, your Security Period would be 7 days.\n\nIf you’ve completed 3-9 orders, your Security Period would be 5 days.\n\nIf you’ve completed over 10 orders, your Security Period would be 3 days.",
      "credit.penalty":
        "'Penalty Credits' refers to the amount of credits that will be deducted from your 'Income Credit'. For example, if you have 10 Income Credit and 1 Penalty Credit. You will only be able to withdraw 9 Credits to Cash.",

      "meals.service": "Meals",
      "hiking.service": "Hiking",
      "finedining.service": "Fine Dining",
      "drinks.service": "Drinks",
      "photoshoot.service": "Photoshoot",
      "gathering.service": "Gathering",
      "movies.service": "Movies",
      "e-meet.service": "E-Meet",
      "karaoke.service": "Karaoke",
      "relationshipadvice.service": "Relationship Advice",
      "emotionalsupport.service": "Emotional Support",
      "wake-upcall.service": "Wake-up Call",
      "sleepcall.service": "Sleep Call",
      "drawing.service": "Drawing",
      "langaugeexchange.service": "Langauge Exchange",
      "upbeatconversation.service": "Upbeat Conversation",
      "fortunetelling.service": "Fortune Telling",
      "offmychest.service": "Off My Chest",
      "invite&earn": "Earn $500+",
      recently: "Recentemente",
      ago: "atrás",
      "unit.second": "segundo",
      "unit.seconds": "segundos",
      "unit.minute": "minuto",
      "unit.minutes": "minutos",
      "unit.hour": "hora",
      "unit.hours": "horas",
      "unit.day": "dia",
      "unit.days": "dias",
      "unit.week": "semana",
      "unit.weeks": "semanas",
      "unit.month": "mês",
      "unit.months": "meses",
      "unit.year": "ano",
      "unit.years": "anos"
    },
  },
  pt: {
    translation: {
      "15Min":"15Min",
      "1Hr":"1Hr",
      "Game":"Jogos",
      "games": "Jogos",
      "for.you":"Para você",
      "ava.today": "Disponível AGORA",
      "gamers": "Jogadores",
      "referral.withdraw.hint": "Você precisará pagar uma taxa de retirada de R$1,00.",
      "credit.referral": "Ganhe uma comissão de 10% sobre as retiradas de créditos dos seus amigos durante os primeiros 3 meses de inscrição.",
      "order.warning":
        "Reembolsos imediatos em créditos estão disponíveis dentro de 72 horas após a compra. Após 72 horas, os créditos poderão ser convertidos em dinheiro. Por favor, reserve com 1 a 2 dias de antecedência.",
      "Double Dates": "Encontro a três",
      "new.users": "Novos usuários",
      "share.profile": "Compartilhar perfil",
      "reason.required": "Por favor escreva um motivo",
      "no.profile": "Não há perfis para esta seleção.",
      "another.service": "Tente pesquisar outro serviço ou filtre novamente uma opção diferente.",
      undo: "Desfazer",
      accept: "Aceitar",
      reject: "Recusar",
      "rejected.reason": "Motivo da recusa",
      "cancel.reason": "Motivo do cancelamento",
      "social.descrip":
        "Me encontre na plataforma Bancar.me.",
      send: "Enviar",
      "final.price": "Preço final",
      "cab.fare": "Taxa de deslocamento",
      date: "Data",
      time: "Horário",
      "wait.user.respond": "Aguardando a resposta do usuário",
      confirm: "Confirmar",
      "make.payment": "Faça o pagamento",
      "pay.to.chat": "Pague para desbloquear a conversa",
      block: "Bloquear",
      unblock: "Desbloquear",
      delete: "Deletar",
      "not.accepting.orders":
        "Desculpe, no momento não estou aceitando novas reservas",
      "no.services": "Este usuário não fornece nenhum serviço",
      "under.review": "Este perfil ainda está em análise",
      "confirm.again":
        "Expirado! Por favor, faça outra reserva para confirmar novamente a disponibilidade do usuário.",
      "order.not.confirm":
        "Esta reserva NÃO está confirmada! A reserva só é confirmada após o seu cliente efetuar o pagamento.",
      "phone.existed": "O número de telefone já existe em outra conta.",
      "wrong.code": "Código de verificação errado",
      "We do NOT allow any forms of physical contact or any form of NSFW services. Rule violation will result in account suspension.":
        "NÃO permitimos qualquer forma de contato físico ou qualquer forma de serviço sexual. A violação da regra resultará na exclusão da conta.",
      "Exchanging of contacts before making payment on the platform will result in account suspension.":
        "A troca de contatos antes de efetuar o pagamento na plataforma resultará na suspensão da conta.",
      "Please avoid off-platform transaction to protect your personal information and getting scam. Any forms of attempts will result in account suspension.":
        "Evite transações fora da plataforma para proteger suas informações pessoais e evitar ser enganado. Qualquer tentativa resultará na suspensão da conta.",
      "expires.in": "Expira em",
      "off-platform.warning":
        "A reserva na plataforma usando Créditos protege suas <b>informações pessoais</b> e evita que você seja <b>enganado</b>. Nós <b>BANIMOS</b> usuários que trocam contatos antes de efetuar o pagamento.",
      read: "Visto",
      "order.expired":
        "Expirado! Seu cliente ainda não efetuou nenhum pagamento. Não continue.",
      expired: "Expirado",
      "bind.phone": "Vincular telefone",
      "skip.step": "Pular esta etapa",
      "subscribe.button": "Inscreva-se",
      "bind.title": "Inscreva-se como PREMIUM ou carregue créditos",
      "bind.description":
        "Queremos saber se você realmente deseja utilizar nossos serviços. Portanto, assine o PREMIUM ou recarregue sua carteira com pelo menos {{credit}} créditos. Caso contrário, vincule seu número de telefone gratuitamente.",
      filter: "Filtrar",
      "recently.active": "Recentemente ativos",
      "highest.ratings": "Avaliações mais altas",
      "lowest.price": "Menor preço",
      "withdraw.warning": "Por favor insira um valor de saque",
      "withdraw.hint":
        "De acordo com os Termos de Serviço, a plataforma Bancar.me cobrará <b>20%</b> do valor do saque como taxa de comissão.<br/><br/>Você também pagará uma taxa de saque de <b>RS$1,00 </b>. Você pode sacar até <b>500 créditos</b> de uma só vez. <b>Os saques são efetivados na hora via PIX.</b>",
      recharge: "Comprar",
      withdraw: "Sacar",
      "profile.inactive.hint":
        "Seu perfil está no modo INATIVO. Vá para 'SEGURANÇA' > 'Ocultar perfil'",
      cancel: "Cancelar",
      "unlock.reason1":
        "Por padrão, todos os chats são bloqueados para evitar conversas desnecessárias. Desbloquear um chat pode impedir que você seja pago por determinados serviços, como encontros virtuais.",
      "unlock.reason2": "Você sempre pode bloquear ou desbloquear um chat quando necessário.",
      "unlock.chat": "Desbloquear chat",
      "lock.chat": "Bloquear chat",
      lock: "Bloquear",
      unlock: "Desbloquear",
      "SERVICE.ORDERS": "Reservas de serviço",
      "request.refund": "Pedir reembolso",
      "view.order": "Ver reesrva",
      "give.review": "Avaliar",
      verification: "Verificação",
      "switch.to.inactive": "Ocultar perfil",
      privacy: "Privacidade",
      rules: "Regras",
      "voice.recording": "Gravador de voz",
      "block.user": "Usuários bloqueados",
      "re.connect.to": "Reconectar-se a",
      "connect.to": "Conectar-se a",
      "view.all.reviews": "Ver todas as avaliações",
      reviews: "Avaliações",
      "turn.on.noti": "Ativar notificação",
      "turn.on": "ATIVAR",
      "how.to.upload.v.doc":
        "Tire uma selfie nítida sua segurando seu documento de identidade emitido pelo governo (carteira de motorista, passaporte, etc...). Você pode tampar qualquer informação no documento, exceto data de nascimento e foto do rosto.",
      "verified.badge": "Os selos verificados ajudam a provar sua identidade",
      "verified.done":
        "Identificação emitida pelo governo e selfie verificadas.",
      "label.done": "Salvar",
      "choose.services": "Escolha um Serviço",
      "gaming.guidelines": "Diretrizes para upload de perfil de jogo",
      photos: "Fotos",
      "join.tele": "GRUPO WHATSAPP!!",
      qna: "Faça uma pergunta",
      "profile.info": "Informação do Perfil",
      "best.label": "Melhores amigos",
      "new.comer": "Novos usuários",
      favourites: "Favoritos",

      "question15.faq": "O que é crédito de saldo?",
      "question16.faq": "O que é crédito pendente?",
      "question17.faq": "O que é receita de crédito?",

      "question13.faq": "O que é crédito de penalidade?",
      "answer13.faq":
        "'Créditos de Penalidade' refere-se ao número de créditos que serão deduzidos do seu 'Crédito de Renda'. Por exemplo, se você tiver 10 Créditos de Renda e 1 Crédito de Penalidade. Você só pode sacar 9 créditos em dinheiro. Os usuários que cometem mau comportamento são penalizados.",

      "question14.faq":
        "Posso receber o pagamento imediatamente após concluir meu serviço?",
      "answer14.faq":
        "Sim! Seu cliente poderá transferir o Crédito Pendente para seu Crédito de Renda imediatamente após confirmar que o serviço foi concluído.",

      "current.location": "Atualmente estou neste local",
      "food.example": "Exemplos: Churrasco, alergia a mariscos, etc...",
      "food.pref.label": "Preferências de Comida (Opcional)",
      "add.info.label": "Informação Adicional",
      "ethnicity.label": "Etnia",
      "username.label": "Nome de usuário",
      "dob.label": "Data de nascimento",
      "gender.label": "Gênero",
      "bio.label": "Apresentação",
      "availability.example": "Exemplo: apenas quarta-feira",
      "public.profile.desc": "Todos podem ver seu perfil",
      "private.profile.desc":
        "Somente usuários que pagam assinaturas mensais ou gastaram XXX créditos poderão ver seu perfil ",
      "upload.voice": "Carregar gravação de voz",
      videos: "Vídeos",
      "upload.media": "Carregar mídia",
      "label.set.price": "Definir limite de preço",
      "label.add.services": "Adicionar serviços",
      "label.update.services": "Atualizar serviços",
      "label.services": "Serviços",
      "dd.required":
        "*Por favor, convide um usuário para participar do seu serviço de encontro duplo",
      "intro1.header": "Renda Alta",
      "intro2.header": "Horários flexíveis",
      "intro3.header": "Conhecer pessoas",

      "intro.review": "A inscrição para Bancar.me está em análise",
      "intro.rejected": "A inscrição para Bancar.me foi recusada",
      "intro.under.review":
        "Geralmente leva entre 24 horas. Você ainda pode atualizar os detalhes da sua inscrição.",
      "intro.title": "O que é um perfil Bancar.me",
      "intro.description":
        "Um perfil Bancar.me oferece serviços de companhia e venda de conteúdo, em troca de créditos.",

      "intro.why": "Por que você deveria se tornal um perfil Bancar.me",

      "intro.why1.title": "Renda Alta",
      "intro.why1.desc":
        "Ganhe mais de 5.000 reais por mês para fazer amigos e conhecer pessoas.",

      "intro.why2.title": "Horários flexíveis",
      "intro.why2.desc":
        " Seja seu próprio chefe. A qualquer momento, em qualquer lugar.",

      "intro.why3.title": "Amplie sua rede de contatos",
      "intro.why3.desc":
        "Conheça pessoas e faça amizades em todo o mundo",

      "intro.eligible.title": "Quem pode participar?",
      "intro.eligible1": " Ter 18 anos ou mais",
      "intro.eligible2":
        " Capaz de fornecer serviços em pelo menos 1 categoria Bancar.me",
      "intro.eligible3":
        " Entusiasmado, bom comunicador, não tóxico e não realiza serviços ilegais.",

      "intro.about.title": "Sobre Bancar.me",
      "intro.about.desc":
        "Bancar.me é uma plataforma de reserva de paquera ou amizade, presencial ou virtual, com vários tipos de serviço que conectam pessoas ao redor do mundo",

      "apply.button": "Inscreva-se grátis",
      "next.button": "Próximo",
      "update.button": "Atualizar",
      "update.app.button": "Solicitação de atualização",
      "submit.app.button": "Enviar solicitação",
      "whereyou.label": "Atualmente estou neste local",
      "require.location.label": "Solicitar",
      "location.hint": " Por favor clique nos nomes fornecidos ",

      "choose.service.label": "Escolha um serviço",
      "update.service.button": "Atualize o serviço",
      "add.service.button": "Adicionar serviço",

      "upload.media.label": "Carregar mídia",
      "attire.rules": "Ler o código de vestuário",
      "voice.rules": "Ler regras de gravação áudio",

      "intro.question": "Por que você deveria se tornal um perfil Bancar.me",
      "income.title": "Receita Alta",
      "income.description":
        "Ganhe mais de 5.000 reais por mês para fazer amigos e conhecer pessoas.",

      "timing.title": "Horário Flexível",
      "timing.description":
        "Seja seu próprio chefe. A qualquer momento, em qualquer lugar.",

      "network.title": "Amplie sua rede de contatos ",
      "network.description":
        "Conheça pessoas e faça amizades em todo o mundo.",

      "history.tab": "Histórico",
      "order.tab": "Pedido",
      "wallet.tab": "Carteira",
      "profile.tab": "Perfil",
      "media.tab": "Mídia",
      "security.tab": "Segurança",
      "location.tab": "Localização",
      "notification.tab": "Notificação",
      "chats.tab": "Conversas",
      "privacy.tab": "Privacidade",

      "submit.button": "Enviar",
      "save.button": "Salvar",
      "delete.account.button": "Deletar conta",

      "request.button": "Fazer reserva",
      "refund.disclaimer":
        "Só emitimos reembolsos dentro de 72 horas a partir da data da reserva",

      "orders.label": "Reservas",
      "views.tab": "Visualizações",

      "loyalpoints.description":
        "Nossa plataforma concede aos usuários devolução do dinheiro e outros privilégios de acordo com a quantidade total de Créditos adquiridos.",
      "shareprofile.label": "Compartilhar perfil",
      "rules3.warning":
        "Banimos usuários que realizam serviços com conteúdo com caráter impróprio ou conotação sexual ou oferecem serviços de mensagens de terceiros (transações fora da plataforma)",
      "rules2.warning":
        "Banimos usuários que forneçam seus contatos nos motivos de recusa/cancelamento.",
      "rules.warning":
        "Expulsamos usuários que solicitam serviços com caráter impróprio ou conotação sexual ou fornecem detalhes de contato de mensagens de terceiros em informações adicionais ",
      "askpayment.instruction":
        "Copie e envie este link ao seu cliente para que ele receba o pagamento imediatamente.",
      "error.label": "Error inesperado",
      "sendcredit.disclaimer": "Compreendo que não é possível emitir um reembolso.",
      "sendcredit.description":
        "Os créditos que você enviou ao usuário serão transferidos imediatamente para a conta bancária do usuário.",
      "confirmation.label": "Confirmação",
      "getpaid.label": "Receba o pagamento imediatamente",
      "servicecompleted.label": "Este serviço foi concluído?",

      "emeet.tab": "Virtual",
      "meetup.tab": "Presencial",
      "games.tab": "Jogos",
      "sports.tab": "Esportes",

      "babe.button": "Criar Perfil",
      "login.button": "Entrar",
      "signup.button": "Registrar-se",
      "signout.button": "Sair",
      "cancel.button": "Cancelar",
      "share.button": "Compartilhar",
      "reviews.button": "Avaliações",
      "report.button": "Denunciar",
      "rent.button": "Quero reservar para alguém",
      "join.button": "Tornar-se um membro GRATIS",
      "rejected.button": "Verifique o motivo da recusa",
      "broadcast.button": "TRANSMTIR",
      "upgrade.button": "SEJA PREMIUM",
      "updatesub.button": "ATUALIZAR ASSINATURA",
      "iamfreetoday.button": "Estou disponível hoje",
      "notfreeanymore.button": "Não estou mais disponível",

      "msg.button": "Conversar",
      "straight.description":
        "Indivíduos que se sentem sexualmente e/ou romanticamente atraídos por membros do sexo oposto.",
      "gay.description":
        "Indivíduos que sentem atração sexual e/ou romântica por pessoas do mesmo sexo.",
      "lesbian.description":
        "Mulheres que sentem atração sexual por outras mulheres.",
      "bisexual.description":
      "Indivíduos que sentem atração sexual por homens e mulheres. Também podem refletir pessoas atraídas por múltiplos gêneros/identidades de gênero.",
      "asexual.description": "Indivíduos que não sentem atração sexual.",
      "demisexual.description":
        "Indivíduos que não sentem atração sexual, a menos que formem uma forte ligação emocional com alguém.",
      "hemoflexible.description":
        "Indivíduos que se identificam como predominantemente homossexuais, mas estão abertos a um encontro heterossexual.",
      "heteroflexible.description":
        "Indivíduos que se identificam como predominantemente heterossexuais, mas estão abertos a um encontro homossexual.",
      "pansexual.description":
        "Indivíduos que se sentem atraídos por pessoas de todo o espectro de sexo, gênero e identidade de gênero.",
      "queer.description":
        "Um termo às vezes usado para se referir a toda a comunidade LGBTQ+ e ao espectro de orientações sexuais e identidades de gênero. Para outros, queer pode refletir a sua identidade sexual e o rótulo que usam para se identificarem.",
      "questioning.description":
        "Indivíduos que questionam e exploram as suas orientações sexuais, identidade de género ou ambas.",
      "grayAsexual.description":
        "Indivíduos do espectro assexuado que experimentam atração sexual e romântica, mas apenas raramente.",
      "reciprosexual.description":
        "Indivíduos que não sentem atração sexual por alguém até saberem que essa pessoa se sente atraída por eles.",
      "akiosexual.description":
        "Indivíduos que sentem atração sexual, mas não querem que ela seja correspondida e se sentiriam desconfortáveis ​​se assim fosse.",
      "aceflux.description":
        "Indivíduos cuja orientação sexual oscila ao longo do espectro entre assexuado e sexual.",
      "grayromantic.description":
        "Indivíduos do espectro arromântico que experimentam atração romântica, mas raramente ou fracamente.",
      "demiromantic.description":
        "Indivíduos que só experimentam atração romântica após desenvolverem previamente uma conexão emocional.",
      "recipromantic.description":
        "Indivíduos que não sentem atração romântica por alguém até saberem que essa pessoa se sente atraída por eles.",
      "akioromantic.description":
        "Indivíduos que podem sentir atração romântica, mas não querem ser correspondidos.",
      "aroflux.description":
        "Indivíduos cuja orientação romântica oscila no espectro entre arromântico e romântico.",

      "coin.hint":
        "Bancar.me usa uma moeda virtual: 1 crédito = R$1 REAL ",

      "signout.dialog": "Você quer sair?",
      "searchlocation.hints":
        "Por favor, clique nos nomes dos locais",
      "howtorent.hints":
        "Seu perfil deve ser atualizado para premium (pagar taxas de assinatura mensal) para poder reservar. Somente usuários com assinatura premium podem reservar",
      "babe.hints":
        "É GRATUITO entrar como Membro. Não há muito mais o que fazer depois que seu perfil aparece no site. Se alguém quiser alugar você, ele enviará uma mensagem para você.",
      "rejected.hints":
        "Talvez seja necessário reenviar sua inscrição novamente.",
      "maintenance.label":
        "Os usuários em Singapura podem enfrentar problemas",
      "changelocation.label": "Mudar localização",
      "filterby.label": "Filtrar por",
      "setvalue.label": "Defina um valor",
      "optional.label": "Opcional",
      "remarks.label": "Observações",
      "comingfrom.label": "De que região vem?",
      "from.label": "De",
      "to.label": "A",
      "setfreetoday.label":
        "Estou disponível hoje em {{convertFromText}} - {{convertToText}}, {{comingText}}. {{infoText}}",
      "race.label": "Corrida",
      "availability.label": "Disponibilidade",
      "height.label": "Altura",
      "drinks.label": "Bebidas",
      "locationat.label": "Localização em",
      "activities.label": "Atividades",
      "numberofmeetups.label": "Quantidade de encontros",
      "foodpreferences.label": "Preferências de comida",
      "iamfreetoday.label":
        "Role para a DIREITA para ver quem está disponível HOJE ",
      "whoisfreetoday.label":
        "{{number_of_profiles}} perfil{{plurals}} {{is_or_are}} disponível HOJE",

      "rent.menulist": "Modelos",
      "messages.menulist": "Mensagens",
      "FAQ.menulist": "Dúvidas Frequentes",
      "terms.menulist": "Regulamento",
      "location.menulist": "Locais",
      "contactus.menulist": "Entre em contato conosco",

      "rent.toolbar": "MODELOS",
      "FAQ.toolbar": "DÚVIDAS",
      "terms.toolbar": "REGULAMENTO",
      "location.toolbar": "LOCAIS",
      "contactus.toolbar": "CONTATO",

      "unlimited.benefit": "Sem taxas de transação ",
      "access.benefit": "Acesso a perfis privados",
      "discount.benefit": "10% mais crédito e 0% de taxas de transação",

      "subscription.disclaimer":
        "Entendo que não oferecemos reembolsos e esta assinatura será renovada automaticamente conforme estabelecido.",
      "platform.disclaimer":
        "Não aprovamos qualquer forma de atividade fraudulenta ou ilegal em nossa plataforma. Todas os encontros DEVEM ser consentidos e em locais onde haja segurança. Qualquer pessoa que violar nossos termos será banida.",

      "question1.faq": "O que é a plataforma Bancar.me?",
      "question2.faq": "Para que podemos usá-lo?",
      "question3.faq": "Como posso me tornar um membro?",
      "question4.faq": "Como posso fazer uma reserva?",
      "question5.faq": "Como recebo o pagamento quando me inscrevo como Membro?",
      "question6.faq": "Quais são as regras e diretrizes importantes?",
      "question7.faq": "Como os perfis Bancar.me são revisados ​​e verificados?",
      "question8.faq": "Posso pedir um reembolso?",
      "question9.faq": " Qual é a comissão para saques de crédito?",
      "question10.faq": "Vocês suportam transações fora da plataforma?",

      "question11.faq": "Por que a conversa está bloqueada?",
      "question12.faq": "Política de Cancelamento e Reembolso ",

      "answer1.faq":
        "Bancar.me é uma plataforma que permite ao usuário reservar uma paquera, uma amizade, ou qualquer pessoa para outra finalidade social.",
      "answer2.faq":
        "Você pode reservar um usuário para passear, ir a um café ou restaurante, ou alguém para acompanhá-lo em uma festa ou evento. Contrate alguém para lhe ensinar uma nova habilidade ou hobby, ou para fazer um tour por uma cidade desconhecida. Nossos membros também oferecem Encontros Virtuais, aconselhamento de relacionamento e outros serviços online. Você também pode contratar um jogador profissional aqui!",
      "answer3.faq":
        "É GRATUITO criar seu perfil. Clique no botão preto 'Criar perfil' e envie sua inscrição. A análise do aplicativo leva cerca de 24 horas. Leia nossas diretrizes para agilizar o processo de inscrição.",
      "answer4.faq":
        "Envie uma solicitação de reserva e, uma vez aceito, efetue o pagamento para desbloquear a conversa. Para a melhor experiência, reserve com 1 a 2 dias de antecedência para garantir um tempo de interação adequado para ambas as partes.",
      "answer5.faq":
        "Os pagamentos são feitos por PIX de forma instantânea ao acessar a carteira e sacar os créditos",
      "answer6.faq":
        "Esta é uma plataforma estritamente legal. Não permitimos serviços ilegais, prostituição, ofensas, desrespeito ou qualquer forma de atividade fraudulenta em nossa plataforma. Todas os encontros DEVEM ser consentidos e em locais onde haja segurança. Qualquer pessoa que violar nossos termos será banida.",
      "answer7.faq":
        "Usamos a verificação do Instagram, um documento de identificação emitido pelo governo para verificação de perfil e também permitimos que os usuários façam avaliações/comentários para cada encontro. Não coletamos, usamos ou divulgamos a identeidade dos usuários. Esconda todas as suas informações confidenciais, exceto sua data de nascimento e sua foto de rosto.",
      "answer8.faq":
        "Os motivos comuns para reembolsos incluem assédio, gravação não consensual, atitude negativa e transações suspeitas. Se alguma vez se sentir desconfortável durante um pedido, você deve sair e solicitar um reembolso e relatar o incidente ao atendimento ao cliente enviando um e-mail para contato@Bancar.me.com.br. Observe que nosso período de reembolso é de 3 dias após a conclusão do serviço. Serviços realizados não podem ser reembolsados ​​se tiverem mais de três dias.",
      "answer9.faq":
        "É uma taxa de plataforma de 20% e uma tarifa de R$1,00 por retirada de crédito. A taxa de saque mantém a plataforma funcionando e nos permite realizar operações para obter mais clientes. Obrigado por seu apoio!",
      "answer10.faq":
        "NÃO oferecemos suporte a transações fora da plataforma. Qualquer usuário que troque contatos antes de efetuar um pagamento resultará na suspensão da conta. Nossas transações na plataforma usando Créditos protegem suas informações pessoais e evitam que você seja enganado. Proibimos usuários que tentem fazer transações fora da plataforma. Quando um cliente paga através da nossa plataforma, ele concorda com os termos de serviços estabelecidos no nosso site.",

      "answer11.faq":
        "Nossa plataforma evita conversas desnecessárias e não permite enviar mensagens diretamente ao usuário. A maioria dos usuários aqui não gosta de rodeios. Ao enviar uma solicitação de reserva, um formulário aparecerá solicitando detalhes importantes como local, horário e data do serviço. As conversas são desbloqueadas após o usuário efetuar o pagamento.",
      "answer12.faq":
        "Um crédito de reembolso de 100% se aplica se o seu acompanhante não comparecer ou se o seu acompanhante cancelar o encontro. Você também terá a opção de reembolso total se o membro se atrasar mais de 30 minutos e VOCÊ não desejar mais continuar com o agendamento. Caso seu acompanhante não compareça, você receberá um reembolso de 100% do crédito e seu acompanhante receberá uma multa.",

      "credit.income":
        "O 'Crédito de Renda' refere-se à renda que você gerou através da prestação de serviços e da coleta de gorjetas. A renda pode ser retirada.",
      "credit.balance":
        "'Crédito' é uma moeda virtual usada no Bancar.me, pode ser usada para pagar serviços e gorjetas. 1,00 crédito = R$1,00\n\n “Saldo de crédito” é o depósito restante em sua conta que pode ser gasto. O dinheiro em ‘Saldo de Crédito’ é uma moeda não sacável que só pode ser gasta na plataforma.",
      "credit.pending":
        "Crédito Pendente refere-se ao seu Crédito de Renda que está dentro do Período de Segurança (3-7 dias). Ele será transferido para sua conta após esse período. \n\nSe você completou menos de 3 pedidos, seu período de segurança será de 7 dias. \n\nSe você completou de 3 a 9 pedidos, seu período de segurança será de 5 dias. \n\nSe você completou mais de 10 pedidos, seu período de segurança será de 3 dias.",
      "credit.penalty":
        "'Créditos de Penalidade' refere-se ao número de créditos que serão deduzidos do seu 'Crédito de Renda'. Por exemplo, se você tiver 10 Créditos de Renda e 1 Crédito de Penalidade. Você só pode sacar 9 créditos em dinheiro.",

      "meals.service": "Comidas",
      "hiking.service": "Caminhada",
      "finedining.service": "Restaurantes Finos",
      "drinks.service": "Bebidas",
      "photoshoot.service": "Sessão de Fotos",
      "gathering.service": "Reuniões",
      "movies.service": "Filmes",
      "e-meet.service": "Encontros Virtuais",
      "karaoke.service": "Karaoke",
      "relationshipadvice.service": "Conselhos amorosos",
      "emotionalsupport.service": "Suporte emocional",
      "wake-upcall.service": "Ligação de despertador",
      "sleepcall.service": "Ligação para Dormir",
      "drawing.service": "Desenhos",
      "langaugeexchange.service": "Intercâmbio de idiomas",
      "upbeatconversation.service": "Conversa animada",
      "fortunetelling.service": "Previsão da sorte",
      "offmychest.service": "Confissões/Desabafo",
      "invite&earn": "Ganhe 5k+",
      recently: "Recentemente",
      ago: "atrás",
      "unit.second": "segundo",
      "unit.seconds": "segundos",
      "unit.minute": "minuto",
      "unit.minutes": "minutos",
      "unit.hour": "hora",
      "unit.hours": "horas",
      "unit.day": "dia",
      "unit.days": "dias",
      "unit.week": "semana",
      "unit.weeks": "semanas",
      "unit.month": "mês",
      "unit.months": "meses",
      "unit.year": "ano",
      "unit.years": "anos"
    },
  },
};
