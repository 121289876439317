function Why1() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="18" fill="#F0F0F0" />
      <path
        d="M13 21.3337C13 23.1746 14.4924 24.667 16.3333 24.667H19.6667C21.5076 24.667 23 23.1746 23 21.3337C23 19.4927 21.5076 18.0003 19.6667 18.0003H16.3333C14.4924 18.0003 13 16.5079 13 14.667C13 12.826 14.4924 11.3337 16.3333 11.3337H19.6667C21.5076 11.3337 23 12.826 23 14.667M18 9.66699V26.3337"
        stroke="#1A1A1A"
        stroke-width="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
function Why2() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="18" fill="#F0F0F0" />
      <path
        d="M17.9997 18.0003L14.4389 15.033C13.9099 14.5922 13.6454 14.3717 13.4552 14.1015C13.2867 13.8621 13.1616 13.5949 13.0855 13.3122C12.9997 12.9931 12.9997 12.6488 12.9997 11.9602V9.66699M17.9997 18.0003L21.5604 15.033C22.0895 14.5922 22.354 14.3717 22.5441 14.1015C22.7126 13.8621 22.8378 13.5949 22.9138 13.3122C22.9997 12.9931 22.9997 12.6488 22.9997 11.9602V9.66699M17.9997 18.0003L14.4389 20.9676C13.9099 21.4085 13.6454 21.6289 13.4552 21.8991C13.2867 22.1386 13.1616 22.4057 13.0855 22.6884C12.9997 23.0075 12.9997 23.3518 12.9997 24.0405V26.3337M17.9997 18.0003L21.5604 20.9676C22.0895 21.4085 22.354 21.6289 22.5441 21.8991C22.7126 22.1386 22.8378 22.4057 22.9138 22.6884C22.9997 23.0075 22.9997 23.3518 22.9997 24.0405V26.3337M11.333 9.66699H24.6663M11.333 26.3337H24.6663"
        stroke="#1A1A1A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
function Why3() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="18" fill="#F0F0F0" />
      <g clip-path="url(#clip0_578_24558)">
        <path
          d="M20.9549 16.2715C21.2666 15.7179 21.5264 15.2183 21.6866 14.838C22.4648 12.9915 21.6953 10.8676 19.8144 10.0013C17.9335 9.13489 16.0448 10.0332 15.2159 11.7678C13.6308 10.6801 11.5163 10.8398 10.3502 12.5372C9.18415 14.2346 9.55012 16.452 11.1472 17.662C11.8721 18.2112 13.3056 19.0205 14.6553 19.7407M21.5813 17.7919C21.2296 15.902 19.6215 14.5198 17.5967 14.8955C15.5719 15.2711 14.2627 17.0979 14.5364 19.0828C14.7564 20.6776 15.9703 24.4193 16.439 25.8286C16.503 26.021 16.5349 26.1171 16.5983 26.1842C16.6534 26.2426 16.7268 26.285 16.8049 26.3035C16.8947 26.3248 16.9939 26.3044 17.1925 26.2637C18.6474 25.9649 22.4948 25.1453 23.9858 24.5384C25.8417 23.783 26.7994 21.7371 26.0821 19.7945C25.3647 17.8519 23.3939 17.1516 21.5813 17.7919Z"
          stroke="#1A1A1A"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_578_24558">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export { Why1, Why2, Why3 };
