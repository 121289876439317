import { FC } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface props extends ButtonProps{

}

const InviteEarnButton : FC<props> = ({...props}) => {

    const [ t ] = useTranslation()

    return <Button size='small' sx={{borderRadius: "99999px"}} color='success' variant='contained' {...props}>
        {t('invite&earn')}
    </Button>
 
}

export default InviteEarnButton