
export default function SvgIconChildren() {
  return (
      <svg
        width="19"
        height="20"
        viewBox="0 0 19 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.9523 10.8923C17.6745 13.5022 16.1957 15.948 13.7487 17.3607C9.6832 19.7079 4.48468 18.315 2.13747 14.2495L1.88747 13.8165M1.04519 9.10664C1.32299 6.49674 2.80178 4.05102 5.24877 2.63825C9.31426 0.29104 14.5128 1.68398 16.86 5.74947L17.11 6.18248M0.992188 16.0654L1.72424 13.3334L4.45629 14.0654M14.5412 5.93349L17.2733 6.66554L18.0053 3.93349M9.49877 5.49947V9.99947L11.9988 11.4995"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
  );
}
