

export default function SvgIconChildren() {
  return (

      <svg
        width="16"
        height="18"
        viewBox="0 0 16 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5 7.51867C13.9669 7.51867 12.5456 7.04365 11.3859 6.23667V12.0598C11.3859 14.9683 8.94395 17.3337 5.94295 17.3337C4.78326 17.3337 3.70743 16.9795 2.82368 16.3781C1.4203 15.4232 0.5 13.8439 0.5 12.0598C0.5 9.15137 2.94194 6.7853 5.94366 6.7853C6.19309 6.7853 6.43678 6.80544 6.67689 6.83669V7.51519V9.76182C6.44467 9.69167 6.19954 9.6507 5.94366 9.6507C4.57325 9.6507 3.458 10.7313 3.458 12.0598C3.458 12.9849 3.99986 13.7884 4.79114 14.1919C5.13589 14.3676 5.52795 14.4683 5.94438 14.4683C7.28326 14.4683 8.37557 13.4363 8.42575 12.1501L8.4279 0.666992H11.3852C11.3852 0.915614 11.4103 1.15798 11.4547 1.39411C11.6633 2.48582 12.332 3.42266 13.2587 4.0081C13.9038 4.41576 14.6736 4.65396 15.4993 4.65396L15.5 7.51867Z"
          fill="black"
        />
      </svg>
  );
}
