//Collection
export const PAGE = "PAGE"
export const USERS = "USERS"
export const QNA = "QNA"
export const PUBLIC = "PUBLIC"
export const STATS = "STATS"
export const INFO = "INFO"
export const REPORT = "REPORT"
export const REVIEWS = "REVIEWS"
export const LISTING = "LISTING"    
export const IPADDRESS = "IPADDRESS"
export const VIEWS = "views"
export const PREMIUM = "PREMIUM"
export const SCHEDULE = "SCHEDULE"
export const GONOW = "GONOW"
export const ANNOUNCE = "ANNOUNCE"
export const PHONE = "PHONE"
export const CONVERSATION = "CONVERSATION"
export const CHATS = "CHATS"
export const MESSAGES = "MESSAGES"
export const BLOCK = "BLOCK"
export const BlockList = "BlockList"
export const NOTIFICATION = "NOTIFICATION"
export const VERIFY = "VERIFY"
export const PROMO = "PROMO"
export const CREDIT = "CREDIT"
export const COINS = "COINS"
export const TRANSACTION = "TRANSACTION"
export const ORDER = "ORDER"
export const SERVICES = "SERVICES"
export const HISTORY = "HISTORY"
export const INVITE = "INVITE"

export const games = "games"
export const foryou = "foryou"
export const title = "title"
export const messageId = "mid"
export const orderIdKey  = "orderId"
export const hasGamingProfileImageForAll = "hasGPFA"

// [ORDER FIELDS]
export const balance = "balance"
export const points = "points"
export const income = "income"
export const pending = "pending"
export const referral = "referral"
export const amount = "amt"
export const orderItem = "item"
export const orderStatus = "status"
export const gateway = "gateway"
export const order = "order"
export const hasOrder = "hasOrder"
export const orderTime = "orderTime"
export const sessionId = "sessionId"
export const wiseTransferIdKey = 'wiseTransferId';
export const myReferrals = "myReferrals";

export const rbac = "rbac"
export const reason = "n"
export const newCountry = "nctry"
export const data = "data"
export const username = "um" //username
export const name = "name"
export const about = "abt"
export const services = "services"
export const suffix = "suffix"
export const legalName = "lgnm"
export const isRefunded = "isRefunded"

export const myServices = "myServices" // fixed bugs, temp

export const category = "category"
export const serviceVersion = "version3"
export const gamer = "gamer"
export const pay_link = "plink"

export const babeUID = "buid"
export const clientUID = "cuid"

export const lowest = "lowest"
export const highest = "highest"
export const uid = "uid" // user id
export const email = "email"
export const code = "code" // user id
export const other_uid = "ouid"
export const uids = "uids"
export const msg = "m"
export const time_stamp = "t" 
export const sortByRatings = "sbyrt" 
export const sortByPricing = "sbyprt" 
export const privacy_time_stamp = "prt"
export const reply = "r"
export const device_token = "dt"
export const admin = "a"
export const featured = "ftd"
export const type = "ty"
export const boostType = "bbty"
export const send_by = "sby"
export const url = "u"
export const block = "b"
export const cache_id = "cid"
export const description = "des"
export const suffix_error = "sfe"
export const index = "index"
export const urls = "urls"
export const bio = "bio"
export const info = "inf"
export const logins = "lgs"
export const documentId = "did"
export const uuid = "uuid"
export const gender = "ge"
export const height = "h"
export const nickname = "nick"
export const terms = "tm"
export const drinks = "dr"
export const from = "from"
export const to = "to"
export const activity = "act"
export const foodPref = "food"
export const version = "ver"
export const my_url = "myu"
export const other_url = "otu"
export const tokens = "tokens"
export const token = "token"
export const customer_id = "customer_id"
export const other_name = "oname"
export const client_secret = "client_secret"
export const pm_id = "pm_id"
export const show = "show"
export const langauge = "lang"
export const nationality = "nlity"
export const raceName = "rnm"
export const voice_url = "vurl"

export const video_urls = "video"
export const video_urls_2 = "video2"

export const cache_video_urls = "cachevid"
export const q_a = "q&a"
export const answers = "answers"
export const questions = "questions"
export const agent = "agent"
export const CONNECTED_STRIPE_ACCOUNT_ID = "csai"
export const whatsapp = "wht"
export const telegram = "tele"
export const phone_number = "phone"
export const phone_number_2 = "phone2"
export const redirect = "redirect"
export const url_height  = "rentbh"
export const url_width  = "rentbw"
export const reject_reason  = "rrn"
export const reject_reason_after  = "rrn2"
export const age = "age"
export const video_verification = "vvn"
export const videoVerificationKey = "vvn"
export const ipAddress = "ip"
export const dob = "dob"
export const privacy = "pry" 
export const isOnline = "online"
export const query = "query"
export const premium = "premium"
export const share1 = "sh1"
export const shiny = "shi"
export const shinyX = "shix" 
export const sponsor = "sponsor"
export const multiple = "multiple"
export const toplist = "toplist"
export const shinyDoc = "shiny"
export const updating = "updating"    
export const coordinates = "coordinates"
export const geo = "geo"
// export const geoEncodings2 = "encods2"
export const geoEncodings = "encods"
export const state = "state"
export const discount = 'discount'
export const host  = "host"
export const joined = "joined"
export const myReferrer = "myReferrer"
export const referrer = "referrer"


// reviews?
export const country = "ctry"
export const recommend = "rec"
export const paymentMethod = "pm"
export const race = "race"
export const memberType = "type"
export const fetish = "fetish"
export const isgAccessTokenKey = "isgat"
export const isg_access_token = "isgat"
export const isg_timestamp_created = "isgtsc"
export const isgTimestampCreated = "isgtsc"
export const isgIdKey = "ium"
export const isg_id = "ium"
export const availability = "ava"
export const views = "vws"
export const price = "pr"
export const completed = "cld"
export const comments = "cmts"
export const ratings = "rts"
export const ratings2 = "rts2"
export const numberOfRentsKey = "nor"
export const number_of_rents = "nor"
export const number_of_refunds = "nof"
export const cache_number_of_rents = "cnor"
export const numberOfRefundsKey = "nof"
export const cacheNumberOfRentsKey = "cnor"
export const mobileUrl = "mbl"
export const vaccinated = "vac"
export const isTelegramConnected = "istele"
export const inviteLink = "ilink"
export const refer = "refer"
export const priceFilterKey = "prf"
export const price_filter = "prf"
export const isApproved = "ipvd"
export const annonymous = "annon"
export const orientation = "orien"
export const origin = "origin"
export const emeets = "emeets"
export const pref = "pref"
export const app = "app"


// announcement
export const ticketIdKey = "ticket_id"
export const contactIdKey = "contact_id"
export const ticket_id = "ticket_id"
export const contact_id = "contact_id"
export const short_link = "link"
export const shortLinkKey = "link"
export const location = "location"
export const isP = "isP"
export const phone = "phone"
export const currency = "crry"
export const additionalInfoKey = "ainfo"
export const additional_info = "ainfo"
export const additionalFeesKey = "fees"
export const additional_fees = "fees"
export const date = "d"
export const time = "ti"
export const status = "st"
export const telegram_message_id = "tmid"
export const telegramMessageIdKey = "tmid"
export const applyTimestampKey = "alyt"
export const apply_time_stamp = "alyt"
export const choosen = 'chsn'

// GO NOW
export const gonowServiceKey = "gnservice"
export const gonowbioKey = "gbio"
export const gonowBlockKey = "gb"
export const gonow_service = "gnservice"
export const gonow_bio = "gbio"
export const gonow_block = "gb"
export const start = "srt"
export const end = "end"
export const comingFrom = "cfrm"
export const cacheTimestamp = "cact"


//Chat Room
export const push = "push"
export const sender = "sen"
export const recipient = "reci"
export const updatedAt = "upat"
export const lastMessage = "lmsg"
export const senderProfileURL = "sepu"
export const senderNickname = "senick"
export const recipientProfileURL = "repu"
export const recipientNickname = "renick"
export const senderLastSeen = "sels"
export const recipientLastSeen = "rels"
export const id = "id"
export const users = "users"
export const lastSeen = "lse"
export const teleIdKey = "teleid"
export const APNSToken = "token"
export const tele_id = "teleid"
export const tele_id_list = "teleids"
export const chat_room_id = "cri"
export const accepted = 'acptd'
export const reviewDocumentID = 'rwdid'
export const deleteOn = "delo"
export const verified = "v"
export const clientRecords = "rds"

export const content = "ctn"
export const createdAt = "crt"
// export const counterKey = "cter"
// export const acceptCounterKey = "accter"

export const stripeConnectAccount = 'scact'
export const stripeApproved = 'sapp'

export const SecurityRangeKey = 'range'
export const moveToWhere = "mtw"
export const paynow = "paynow"
export const issuedPayment = "ispyt"
export const penalty = "penalty"

//price limit
export const priceLimit = "prilmt"
export const operatorKey = "opkey"
export const walletLimitKey = "wlmt"
export const spendLimitKey = "slmt"

export const club = "club"
export const clubs = "clubs"

export const block_bc = "bc"

export const AgencyFacebook = "facebook"
export const AgencyWhatsapp = "whatsapp"
export const AgencyInstagram = "instagram"
export const AgencyEmail = "email"