export default function SvgIconChildren() {
  return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.3346 10.051C18.3346 14.2353 15.2832 17.7042 11.2999 18.3337V12.4907H13.2367L13.6055 10.0733H11.2999V8.50483C11.2999 7.8432 11.6221 7.19903 12.6541 7.19903H13.702V5.14076C13.702 5.14076 12.7506 4.97727 11.8416 4.97727C9.94297 4.97727 8.70269 6.13496 8.70269 8.23026V10.0726H6.59227V12.49H8.70269V18.333C4.72005 17.7028 1.66797 14.2346 1.66797 10.051C1.66797 5.42092 5.39922 1.66699 10.0013 1.66699C14.6034 1.66699 18.3346 5.42022 18.3346 10.051Z"
          fill="black"
        />
      </svg>
  );
}
