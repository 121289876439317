export default function SvgIconChildren() {
  return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_2515_6001)">
          <path
            d="M11.0164 7.63571C11.4187 6.7881 12.5812 6.7881 12.9836 7.6357L13.6789 9.10059C13.8387 9.43717 14.1476 9.67047 14.5048 9.72444L16.0597 9.95935C16.9593 10.0953 17.3186 11.2448 16.6676 11.9045L15.5425 13.0448C15.2839 13.3068 15.166 13.6843 15.227 14.0542L15.4926 15.6643C15.6463 16.5959 14.7058 17.3063 13.9011 16.8665L12.5104 16.1063C12.1909 15.9316 11.8091 15.9316 11.4896 16.1063L10.0989 16.8665C9.2942 17.3063 8.35371 16.5959 8.50739 15.6643L8.77299 14.0542C8.83402 13.6843 8.71606 13.3068 8.45754 13.0448L7.33244 11.9045C6.68143 11.2448 7.04066 10.0953 7.94033 9.95935L9.49519 9.72444C9.85244 9.67047 10.1613 9.43717 10.3211 9.10059L11.0164 7.63571Z"
            fill="white"
          />
          <path
            d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM1.67882 12C1.67882 17.7002 6.29977 22.3212 12 22.3212C17.7002 22.3212 22.3212 17.7002 22.3212 12C22.3212 6.29977 17.7002 1.67882 12 1.67882C6.29977 1.67882 1.67882 6.29977 1.67882 12Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_2515_6001">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>

  );
}
