import {useState, useRef} from 'react';

import { filterDrinks, filterGender, filterPrice, filterProfile, filterRace, pageArea, serviceIndexKey } from '../../../keys/localStorageKeys';
import { Dialog, DialogActions, 
  DialogContent, Button, FormControlLabel, Radio, RadioGroup, Typography} from '@mui/material';


import { area as areaKey } from '../../../keys/localStorageKeys';

import GeoInput from '../../Inputs/GeoInput';
import { club, state } from '../../../keys/firestorekeys';
import { useUser } from '../../../store';

export interface ConfirmationDialogRawProps {
  keepMounted: boolean
  value: string | undefined
  open: boolean
  onClose: (value?: string) => void
}

export default function CountriesDialog(props: ConfirmationDialogRawProps) {

  const [currentCountryCode]= useUser((state) => [state.currentUser?.countryCode])
  const [currentStateCode]= useUser((state) => [state.currentUser?.stateCode])


  const clubName = sessionStorage.getItem(club)
  const clubState = sessionStorage.getItem(state)

  const { onClose, value: valueProp, open, ...other } = props;

  const [placeSelected, setPlaceSelected] = useState<string | undefined>()

  const radioGroupRef = useRef<HTMLElement>(null);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {

    if(placeSelected){
      // const venue = value.split(", ")[0]
      const key = (clubName && clubState) ? pageArea : areaKey
      localStorage.setItem(key, placeSelected)
    } 

    localStorage.removeItem(filterDrinks)
    localStorage.removeItem(filterProfile)
    localStorage.removeItem(filterGender)
    localStorage.removeItem(filterPrice)
    localStorage.removeItem(filterRace)
    localStorage.removeItem(serviceIndexKey)

    window.location.href = `page/Rent?no=${(new Date().getTime())}`
  }

  const onPlaceSelected = (formatted_address: string | undefined) => {
    setPlaceSelected(formatted_address)

  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlaceSelected((event.target as HTMLInputElement).value);
  };


  return (
    <Dialog
      onClose={handleCancel}
      fullWidth
      maxWidth="xs"
      onTransitionEnd={handleEntering}
      open={open}
      {...other}>

      <DialogContent dividers>

      <br/>
      
      <div>
        <GeoInput searchFor='location' defaultValue={valueProp} onPlaceSelected={onPlaceSelected}/>
      </div>

      <br/>
      <Typography fontSize={12}>Cidades populares</Typography>

      <RadioGroup
        defaultValue={""}
          ref={radioGroupRef}
          onChange={handleChange}>

          {
                    currentStateCode === "BR-MG" ? <>
                  <FormControlLabel value={'Belo Horizonte'}  control={<Radio color='secondary'/>} label={'Belo Horizonte'}/> 
                  <FormControlLabel value={'Uberlândia'}  control={<Radio color='secondary'/>} label={'Uberlândia'}/>
                  <FormControlLabel value={'Contagem'} control={<Radio color='secondary'/>} label={'Contagem'}/>
                  <FormControlLabel value={'Juiz de Fora'}  control={<Radio color='secondary'/>} label={'Juiz de Fora'}/>
                  <FormControlLabel value={'Montes Claros'}  control={<Radio color='secondary'/>} label={'Montes Claros'}/>
              </> : currentStateCode === "BR-SP" ? <>
                    <FormControlLabel value={'São Paulo'}  control={<Radio color='secondary'/>} label={'São Paulo'}/>
                    <FormControlLabel value={'Guarulhos'}  control={<Radio color='secondary'/>} label={'Guarulhos'}/> 
                    <FormControlLabel value={'Campinhas'} control={<Radio color='secondary'/>} label={'Campinas'}/>
                    <FormControlLabel value={'São Bernardo do Campo'}  control={<Radio color='secondary'/>} label={'São Bernardo do Campo'}/>
                    <FormControlLabel value={'Santo André'}  control={<Radio color='secondary'/>} label={'Santo André'}/>
              </> : currentStateCode === "BR-RJ" ? <>
                    <FormControlLabel value={'Rio de Janeiro'} control={<Radio color='secondary'/>} label={'Rio de Janeiro'}/>
                    <FormControlLabel value={'São Gonçalo'}  control={<Radio color='secondary'/>} label={'São Gonçalo'}/> 
                    <FormControlLabel value={'Duque de Caxias'}  control={<Radio color='secondary'/>} label={'Duque de Caxias'}/>
                    <FormControlLabel value={'Nova Iguaçu'}  control={<Radio color='secondary'/>} label={'Nova Iguaçu'}/>
                    <FormControlLabel value={'Campos dos Goytacazes'}  control={<Radio color='secondary'/>} label={'Campos dos Goytacazes'}/>
              </> : currentStateCode === "BR-GO" ? <>
                    <FormControlLabel value={'Goiânia'} control={<Radio color='secondary'/>} label={'Goiânia'}/>
                    <FormControlLabel value={'Aparecida de Goiânia'}  control={<Radio color='secondary'/>} label={'Aparecida de Goiânia'}/> 
                    <FormControlLabel value={'Anápolis'}  control={<Radio color='secondary'/>} label={'Anápoliss'}/>
                    <FormControlLabel value={'Rio Verde'}  control={<Radio color='secondary'/>} label={'Rio Verde'}/>
                    <FormControlLabel value={'Águas Lindas de Goiás'}  control={<Radio color='secondary'/>} label={'Águas Lindas de Goiás'}/>
              </> : currentStateCode === "BR-BA" ? <>
                    <FormControlLabel value={'Salvador'} control={<Radio color='secondary'/>} label={'Salvador'}/>
                    <FormControlLabel value={'Feira de Santana'}  control={<Radio color='secondary'/>} label={'Feira de Santana'}/> 
                    <FormControlLabel value={'Vitória da Conquista'}  control={<Radio color='secondary'/>} label={'Vitória da Conquista'}/>
                    <FormControlLabel value={'Camaçari'}  control={<Radio color='secondary'/>} label={'Camaçari'}/>
                    <FormControlLabel value={'Juazeiro'}  control={<Radio color='secondary'/>} label={'Juazeiro'}/>
              </> : currentStateCode === "BR-ES" ? <>
                    <FormControlLabel value={'Serra'} control={<Radio color='secondary'/>} label={'Serra'}/>
                    <FormControlLabel value={'Vila Velha'}  control={<Radio color='secondary'/>} label={'Vila Velha'}/> 
                    <FormControlLabel value={'Cariacica'}  control={<Radio color='secondary'/>} label={'Cariacica'}/>
                    <FormControlLabel value={'Vitória'}  control={<Radio color='secondary'/>} label={'Vitória'}/>
                    <FormControlLabel value={'Cachoeiro de Itapemirim'}  control={<Radio color='secondary'/>} label={'Cachoeiro de Itapemirim'}/>
              </> : currentStateCode === "BR-PR" ? <>
                    <FormControlLabel value={'Curitiba'} control={<Radio color='secondary'/>} label={'Curitiba'}/>
                    <FormControlLabel value={'Londrina'}  control={<Radio color='secondary'/>} label={'Londrina'}/> 
                    <FormControlLabel value={'Maringá'}  control={<Radio color='secondary'/>} label={'Maringá'}/>
                    <FormControlLabel value={'Ponta Grossa'}  control={<Radio color='secondary'/>} label={'Ponta Grossa'}/>
                    <FormControlLabel value={'Cascavel'}  control={<Radio color='secondary'/>} label={'Cascavel'}/>
              </> : currentStateCode === "BR-SC" ? <>
                    <FormControlLabel value={'Joinville'} control={<Radio color='secondary'/>} label={'Joinville'}/>
                    <FormControlLabel value={'Florianópolis'}  control={<Radio color='secondary'/>} label={'Florianópolis'}/> 
                    <FormControlLabel value={'Blumenau'}  control={<Radio color='secondary'/>} label={'Blumenau'}/>
                    <FormControlLabel value={'São José'}  control={<Radio color='secondary'/>} label={'São José'}/>
                    <FormControlLabel value={'Balneário Camboriú'}  control={<Radio color='secondary'/>} label={'Balneário Camboriú'}/>
              </> : currentStateCode === "BR-RS" ? <>
                    <FormControlLabel value={'Porto Alegre'} control={<Radio color='secondary'/>} label={'Porto Alegre'}/>
                    <FormControlLabel value={'Caxias do Sul'}  control={<Radio color='secondary'/>} label={'Caxias do Sul'}/> 
                    <FormControlLabel value={'Canoas'}  control={<Radio color='secondary'/>} label={'Canoas'}/>
                    <FormControlLabel value={'Pelotas'}  control={<Radio color='secondary'/>} label={'Pelotas'}/>
                    <FormControlLabel value={'Santa Maria'}  control={<Radio color='secondary'/>} label={'Santa Maria'}/>
              </> : currentStateCode === "BR-MS" ? <>
                    <FormControlLabel value={'Campo Grande'} control={<Radio color='secondary'/>} label={'Campo Grande'}/>
                    <FormControlLabel value={'Dourados'}  control={<Radio color='secondary'/>} label={'Dourados'}/> 
                    <FormControlLabel value={'Três Lagoas'}  control={<Radio color='secondary'/>} label={'Três Lagoas'}/>
                    <FormControlLabel value={'Corumbá'}  control={<Radio color='secondary'/>} label={'Corumbá'}/>
                    <FormControlLabel value={'Ponta Porã'}  control={<Radio color='secondary'/>} label={'Ponta Porã'}/>
              </> : currentStateCode === "BR-MT" ? <>
                    <FormControlLabel value={'Cuiabá'} control={<Radio color='secondary'/>} label={'Cuiabá'}/>
                    <FormControlLabel value={'Várzea Grande'}  control={<Radio color='secondary'/>} label={'Várzea Grande'}/> 
                    <FormControlLabel value={'Rondonópolis'}  control={<Radio color='secondary'/>} label={'Rondonópolis'}/>
                    <FormControlLabel value={'Sinop'}  control={<Radio color='secondary'/>} label={'Sinop'}/>
                    <FormControlLabel value={'Sorriso'}  control={<Radio color='secondary'/>} label={'Sorriso'}/>
              </> : currentStateCode === "BR-RO" ? <>
                    <FormControlLabel value={'Porto Velho'} control={<Radio color='secondary'/>} label={'Porto Velho'}/>
                    <FormControlLabel value={'Ji-Paraná'}  control={<Radio color='secondary'/>} label={'Ji-Paraná'}/> 
                    <FormControlLabel value={'Ariquemes'}  control={<Radio color='secondary'/>} label={'Ariquemes'}/>
                    <FormControlLabel value={'Vilhena'}  control={<Radio color='secondary'/>} label={'Vilhena'}/>
                    <FormControlLabel value={'Cacoal'}  control={<Radio color='secondary'/>} label={'Cacoal'}/>
              </> : currentStateCode === "BR-AC" ? <>
                    <FormControlLabel value={'Rio Branco'} control={<Radio color='secondary'/>} label={'Rio Branco'}/>
                    <FormControlLabel value={'Cruzeiro do Sul'}  control={<Radio color='secondary'/>} label={'Cruzeiro do Sul'}/> 
                    <FormControlLabel value={'Tarauacá'}  control={<Radio color='secondary'/>} label={'Tarauacá'}/>
                    <FormControlLabel value={'Sena Madureira'}  control={<Radio color='secondary'/>} label={'Sena Madureira'}/>
                    <FormControlLabel value={'Feijó'}  control={<Radio color='secondary'/>} label={'Feijó'}/>
              </> : currentStateCode === "BR-AM" ? <>
                    <FormControlLabel value={'Manaus'} control={<Radio color='secondary'/>} label={'Manaus'}/>
                    <FormControlLabel value={'Itacoatiara'}  control={<Radio color='secondary'/>} label={'Itacoatiara'}/> 
                    <FormControlLabel value={'Manacapuru'}  control={<Radio color='secondary'/>} label={'Manacapuru'}/>
                    <FormControlLabel value={'Parintins'}  control={<Radio color='secondary'/>} label={'Parintins'}/>
                    <FormControlLabel value={'Tefé'}  control={<Radio color='secondary'/>} label={'Tefé'}/>
              </> : currentStateCode === "BR-RR" ? <>
                    <FormControlLabel value={'Boa Vista'} control={<Radio color='secondary'/>} label={'Boa Vista'}/>
                    <FormControlLabel value={'Rorainópolis'}  control={<Radio color='secondary'/>} label={'Rorainópolis'}/> 
                    <FormControlLabel value={'Alto Alegre'}  control={<Radio color='secondary'/>} label={'Alto Alegre'}/>
                    <FormControlLabel value={'Caracaraí'}  control={<Radio color='secondary'/>} label={'Caracaraí'}/>
                    <FormControlLabel value={'Pacaraima'}  control={<Radio color='secondary'/>} label={'Pacaraima'}/>
              </> : currentStateCode === "BR-AP" ? <>
                    <FormControlLabel value={'Macapá'} control={<Radio color='secondary'/>} label={'Macapá'}/>
                    <FormControlLabel value={'Santana'}  control={<Radio color='secondary'/>} label={'Santana'}/> 
                    <FormControlLabel value={'Laranjal do Jari'}  control={<Radio color='secondary'/>} label={'Laranjal do Jari'}/>
                    <FormControlLabel value={'Oiapoque'}  control={<Radio color='secondary'/>} label={'Oiapoque'}/>
                    <FormControlLabel value={'Mazagão'}  control={<Radio color='secondary'/>} label={'Mazagão'}/>
              </> : currentStateCode === "BR-PA" ? <>
                    <FormControlLabel value={'Belém'} control={<Radio color='secondary'/>} label={'Belém'}/>
                    <FormControlLabel value={'Ananindeua'}  control={<Radio color='secondary'/>} label={'Ananindeua'}/> 
                    <FormControlLabel value={'Santarém'}  control={<Radio color='secondary'/>} label={'Santarém'}/>
                    <FormControlLabel value={'Parauapebas'}  control={<Radio color='secondary'/>} label={'Parauapebas'}/>
                    <FormControlLabel value={'Marabá'}  control={<Radio color='secondary'/>} label={'Marabá'}/>
              </> : currentStateCode === "BR-MA" ? <>
                    <FormControlLabel value={'São Luís'} control={<Radio color='secondary'/>} label={'São Luís'}/>
                    <FormControlLabel value={'Imperatriz'}  control={<Radio color='secondary'/>} label={'Imperatriz'}/> 
                    <FormControlLabel value={'São José de Ribamar'}  control={<Radio color='secondary'/>} label={'São José de Ribamar'}/>
                    <FormControlLabel value={'Timon'}  control={<Radio color='secondary'/>} label={'Timon'}/>
                    <FormControlLabel value={'Caxias'}  control={<Radio color='secondary'/>} label={'Caxias'}/>
              </> : currentStateCode === "BR-TO" ? <>
                    <FormControlLabel value={'Palmas'} control={<Radio color='secondary'/>} label={'Palmas'}/>
                    <FormControlLabel value={'Araguaína'}  control={<Radio color='secondary'/>} label={'Araguaína'}/> 
                    <FormControlLabel value={'Gurupi'}  control={<Radio color='secondary'/>} label={'Gurupi'}/>
                    <FormControlLabel value={'Porto Nacional'}  control={<Radio color='secondary'/>} label={'Porto Nacional'}/>
                    <FormControlLabel value={'Paraíso do Tocantins'}  control={<Radio color='secondary'/>} label={'Paraíso do Tocantins'}/>
              </> : currentStateCode === "BR-PI" ? <>
                    <FormControlLabel value={'Teresina'} control={<Radio color='secondary'/>} label={'Teresina'}/>
                    <FormControlLabel value={'Parnaíba'}  control={<Radio color='secondary'/>} label={'Parnaíba'}/> 
                    <FormControlLabel value={'Picos'}  control={<Radio color='secondary'/>} label={'Picos'}/>
                    <FormControlLabel value={'Uruçuí'}  control={<Radio color='secondary'/>} label={'Uruçuí'}/>
                    <FormControlLabel value={'Floriano'}  control={<Radio color='secondary'/>} label={'Floriano'}/>
              </> : currentStateCode === "BR-CE" ? <>
                    <FormControlLabel value={'Fortaleza'} control={<Radio color='secondary'/>} label={'Fortaleza'}/>
                    <FormControlLabel value={'Caucaia'}  control={<Radio color='secondary'/>} label={'Caucaia'}/> 
                    <FormControlLabel value={'Juazeiro do Norte'}  control={<Radio color='secondary'/>} label={'Juazeiro do Norte'}/>
                    <FormControlLabel value={'Maracanaú'}  control={<Radio color='secondary'/>} label={'Maracanaú'}/>
                    <FormControlLabel value={'Sobral'}  control={<Radio color='secondary'/>} label={'Sobral'}/>
              </> : currentStateCode === "BR-RN" ? <>
                    <FormControlLabel value={'Natal'} control={<Radio color='secondary'/>} label={'Natal'}/>
                    <FormControlLabel value={'Mossoró'}  control={<Radio color='secondary'/>} label={'Mossoró'}/> 
                    <FormControlLabel value={'Parnamirim'}  control={<Radio color='secondary'/>} label={'Parnamirim'}/>
                    <FormControlLabel value={'São Gonçalo do Amarante'}  control={<Radio color='secondary'/>} label={'São Gonçalo do Amarante'}/>
                    <FormControlLabel value={'Macaíba'}  control={<Radio color='secondary'/>} label={'Macaíba'}/>
              </> : currentStateCode === "BR-PB" ? <>
                    <FormControlLabel value={'João Pessoa'} control={<Radio color='secondary'/>} label={'João Pessoa'}/>
                    <FormControlLabel value={'Campina Grande'}  control={<Radio color='secondary'/>} label={'Campina Grande'}/> 
                    <FormControlLabel value={'Santa Rita'}  control={<Radio color='secondary'/>} label={'Santa Rita'}/>
                    <FormControlLabel value={'Patos'}  control={<Radio color='secondary'/>} label={'Patos'}/>
                    <FormControlLabel value={'Bayeux'}  control={<Radio color='secondary'/>} label={'Bayeux'}/>
              </> : currentStateCode === "BR-PE" ? <>
                    <FormControlLabel value={'Recife'} control={<Radio color='secondary'/>} label={'Recife'}/>
                    <FormControlLabel value={'Jaboatão dos Guararapes'}  control={<Radio color='secondary'/>} label={'Jaboatão dos Guararapes'}/> 
                    <FormControlLabel value={'Olinda'}  control={<Radio color='secondary'/>} label={'Olinda'}/>
                    <FormControlLabel value={'Caruaru'}  control={<Radio color='secondary'/>} label={'Caruaru'}/>
                    <FormControlLabel value={'Petrolina'}  control={<Radio color='secondary'/>} label={'Petrolina'}/>
              </> : currentStateCode === "BR-DF" ? <>
                    <FormControlLabel value={'Ceilândia'} control={<Radio color='secondary'/>} label={'Ceilândia'}/>
                    <FormControlLabel value={'Samambaia'}  control={<Radio color='secondary'/>} label={'Samambaia'}/> 
                    <FormControlLabel value={'Plano Piloto'}  control={<Radio color='secondary'/>} label={'Plano Piloto'}/>
                    <FormControlLabel value={'Taguatinga'}  control={<Radio color='secondary'/>} label={'Taguatinga'}/>
                    <FormControlLabel value={'Planaltina'}  control={<Radio color='secondary'/>} label={'Planaltina'}/>
              </> : <>
                    <FormControlLabel value={'São Paulo'}  control={<Radio color='secondary'/>} label={'São Paulo'}/> 
                    <FormControlLabel value={'Rio de Janeiro'}  control={<Radio color='secondary'/>} label={'Rio de Janeiro'}/>
                    <FormControlLabel value={'Brasília'} control={<Radio color='secondary'/>} label={'Brasília'}/>
                    <FormControlLabel value={'Goiânia'}  control={<Radio color='secondary'/>} label={'Goiânia'}/>
                    <FormControlLabel value={'Florianópolis'}  control={<Radio color='secondary'/>} label={'Florianópolis'}/>
              </>

            }

            {/* {currentCountryCode === "ID" && <FormControlLabel value={'South Jakarta, South Jakarta City, Jakarta, Indonesia'}  control={<Radio color='secondary'/>} label={'South Jakarta'}/> }
            {currentCountryCode === "ID" && <FormControlLabel value={'Central Jakarta, Central Jakarta City, Jakarta, Indonesia'}  control={<Radio color='secondary'/>} label={'Central Jakarta'}/> }
            {currentCountryCode === "ID" && <FormControlLabel value={'North Jakarta, North Jakarta City, Jakarta, Indonesia'}  control={<Radio color='secondary'/>} label={'North Jakarta'}/> }
            {currentCountryCode === "ID" && <FormControlLabel value={'West Jakarta, West Jakarta City, Jakarta, Indonesia'}  control={<Radio color='secondary'/>} label={'West Jakarta'}/> }
            {currentCountryCode === "ID" && <FormControlLabel value={'East Jakarta, West Jakarta City, Jakarta, Indonesia'}  control={<Radio color='secondary'/>} label={'East Jakarta'}/> } */}
      </RadioGroup>

      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={handleCancel} color='secondary' variant="contained">
          Cancelar
        </Button>

        <Button disabled={!placeSelected} onClick={handleOk} color="secondary" variant="contained">
          BUSCAR
        </Button>

      </DialogActions>
    </Dialog>
  );
}