export const mobileWidth = 620
export const widthToOpenModal = 750
export const iconImageSize = 24
export const hideFilterButtonSize = 720
export const dummyHeightSizes = [
    250, 280, 300, 260, 278, 300, 300, 266, 250, 280, 
    290, 300, 300, 290, 290, 270, 300, 300, 290, 290, 
    270, 290, 280, 290, 300, 300, 280, 290, 264, 278,
    290, 290, 290, 278, 288, 288, 260, 262, 268, 270,
    280, 288, 286, 288, 290, 296, 290, 298, 280, 258,
    280, 280, 286, 270, 270, 250, 252, 260, 268, 288,
    290, 290, 286, 290, 296, 280, 288, 268, 270, 270,
    260, 260, 280, 268, 268, 260, 270, 288, 280, 280,
    258, 260, 262, 282, 280, 280, 270, 258, 258, 258,
    250, 250, 250, 260, 260, 250, 262, 286, 288, 288]