import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getFunctions} from 'firebase/functions'; 
import { getStorage } from "firebase/storage"

//PROD
//export const config = {
//    apiKey: "AIzaSyDaWoG7RzsFdNQyJjzfpD382yTND-tPZ0c",
//    appId: "1:1057898223358:web:182f8b7d4d447ef028b7af",
//    authDomain: "figma-ebuddy.firebaseapp.com",
//    databaseURL: "https://figma-ebuddy.firebaseio.com",
//    measurementId: "G-KQKYSNXQ2R",
//    messagingSenderId: "1057898223358",
//    projectId: "figma-ebuddy",
//    storageBucket: "figma-ebuddy.appspot.com"
//}

//ULTIMO
//export const config = {//AIzaSyAA9V_QNh98I6tnB9A11tdRPV8ztmhQWMs
//    apiKey: "AIzaSyCybxRzibsg1z63tfOLItaYTdxLLE42620",
//    projectId: "rent-a-date-81735",
//    appId: "1:464795139098:web:87ce25ea4154dad390e5a3",
//    databaseURL: "https://rent-a-date-81735.firebaseio.com",
//    storageBucket: "rent-a-date-81735.appspot.com",
//    locationId: "us-central",
//    authDomain: "rent-a-date-81735.firebaseapp.com",
//    messagingSenderId: "464795139098",
//    measurementId: "G-K2SHMBYTJ3"
//  }
//AIzaSyDaWoG7RzsFdNQyJjzfpD382yTND-tPZ0c
//AIzaSyDyT5W0Jh49F30Pqqtyfdf7pDLFKLJoAnw foi
//AIzaSyAA9V_QNh98I6tnB9A11tdRPV8ztmhQWMs
//AIzaSyAcm-BxZTYAhshcuDByBU9Ljzh5l1oleMM foi
//AIzaSyAO_FJ2SlqU8Q4STEHLGCilw_Y9_11qcW8 foi
//DEV
// export const config = {
//     apiKey: "IzaSyAcswhJK-w_UinJq0ofECG4-sQ_LROTyWc",
//     authDomain: "rb-dev-819c4.firebaseapp.com",
//     projectId: "rb-dev-819c4",
//     storageBucket: "rb-dev-819c4.appspot.com",
//     messagingSenderId: "423090213827",
//     appId: "1:423090213827:web:3f0dfdbbbf437160306376",
 //    measurementId: "G-0SZYQ6EDDQ"
 //}



 //DEV
// export const config = {
//   apiKey: "AIzaSyDsbhYmZCYEUBO3-H-wk3FWYKQedigwaHs",
//    appId: "1:423090213827:web:3f0dfdbbbf437160306376",
//    authDomain: "rb-dev-819c4.firebaseapp.com",
//    databaseURL: "",
//    measurementId: "G-0SZYQ6EDDQ",
//    messagingSenderId: "423090213827",
//    projectId: "rb-dev-819c4",
//    storageBucket: "rb-dev-819c4.appspot.com"
//  }


// export const config = {
//    apiKey: "AIzaSyDMJGMsbex8JVeZi938s0zYNZEmMeTgjio",
//    appId: "1:464795139098:web:87ce25ea4154dad390e5a3",
//    authDomain: "rent-a-date-81735.firebaseapp.com",
//    databaseURL: "https://rent-a-date-81735.firebaseio.com",
//    measurementId: "G-K2SHMBYTJ3",
//    messagingSenderId: "464795139098",
//    projectId: "rent-a-date-81735",
//    storageBucket: "rent-a-date-81735.appspot.com"
//  }


export const config = {
  apiKey: "AIzaSyCg2Rl7gO_CASG-0Ml6rjsRcAWkPoII6s8",
  authDomain: "bancar.me", //algar-1616501032135.firebaseapp.com
  databaseURL: "https://algar-1616501032135-default-rtdb.firebaseio.com",
  projectId: "algar-1616501032135",
  storageBucket: "algar-1616501032135.appspot.com",
  messagingSenderId: "759630716122",
  appId: "1:759630716122:web:21f120906602f49890d9e3",
  measurementId: "G-3QVQV1PWGL"
};

// MARIA EDUARDA +55 34 99919-1559	111111

// HELENA +55 34 99919-1558	111111

// DEV CREDENTIALS
// Phone number:     Code:
// +1 613-604-3879	111111	
// +880 1723-111581	123456	
// +65 9123 4567	888999	
// +65 9876 5432	898989	
// +880 1734-111591	123456	
// +92 307 2894423	112233	
// +91 96873 67261	232323	
// +65 77778888	777888	
// +65 11112222	111222

// WISE DEV
// export const config = {
//     apiKey: "AIzaSyDaabSVFEXV5AnFCOke17W52NcEOAxkwZE",
//     authDomain: "wise-dev-9300e.firebaseapp.com",
//     projectId: "wise-dev-9300e",
//     storageBucket: "wise-dev-9300e.appspot.com",
//     messagingSenderId: "566098260985",
//     appId: "1:566098260985:web:ea1a149be0fe31bd978328",
//     measurementId: "G-0DWC6D5LYH"
// };

export const firebaseApp = initializeApp(config)
export const storage = getStorage(firebaseApp)
export const db = getFirestore(firebaseApp)
export const auth = getAuth(firebaseApp)
export const analytics = getAnalytics(firebaseApp)
export const functions = getFunctions(firebaseApp)
//connectFunctionsEmulator(functions, "localhost", 5001);
