import { Alert, Button, Typography } from '@mui/material';
import { FC } from 'react';
import shallow from 'zustand/shallow';
import { useUser } from '../../store';

const BanAlert : FC<{
    bannedReason: string | undefined
}> = ({bannedReason}) => {

    const [uid, nickname] = useUser((state) => [
        state?.currentUser?.uid, 
        state?.currentUser?.nickname
    ], shallow)

    const onClick = () => {
        const subject = `Assuntos PROIBIDOS de ${nickname ?? ""} Id: ${uid}`
        window.open(`mailto:contato@bancar.me?subject=${subject}` , '_blank')
    }

    return <Alert
            sx={{marginTop: "64px"}}  
            action={
             <Button variant='contained' color="error" size="small">
             NOS ENVIEI UM EMAIL
           </Button>
        } onClick={onClick} severity="warning">
            Recebemos relatos de violação de nossos termos e regras. Levamos questões como esta a sério, pois estamos tentando construir uma comunidade segura e protegida para nossos membros, e tomaremos medidas imediatas contra qualquer pessoa que possa abusar da plataforma ou dos membros.
            {bannedReason && <Typography marginTop={2} fontWeight="bold">Banido por: {bannedReason}</Typography>}
        </Alert> 
 
}

export default BanAlert